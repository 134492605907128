/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-08-01",
    versionChannel: "nightly",
    buildDate: "2023-08-01T00:22:57.291Z",
    commitHash: "2621dd78eefab17e98dc03fafa25ed421784a712",
};
